<template lang="pug">
.icon(
  :class="takeModifier"
)
  FontAwesomeIcon.icon__image(
    v-if="!file"
    :icon="icon"
  )
  img.icon__image(
    v-else
    :src="file"
    alt="Icon"
  )

  span.icon__label(
    v-if="texts"
    v-for="(item, index) in texts"
    for="image"
    :class="item.click ? 'icon__label--button' : ''"
    @click="item.click ? $emit(`click${index}`) : ''"
  ) {{item.text}}
    FontAwesomeIcon.icon__label.icon__label--icon(
      v-if="item.icon"
      :icon="item.icon"
    )

  label.icon__label(
    v-if="label"
    :class="takeLabelModifier"
    for="image"
  ) {{label}}

  input(
    v-if="changeImage && label"
    v-show="false"
    type="file"
    id="image"
    @change="doSelectFile"
  )
</template>

<script>
// import {
//   mapGetters,
//   mapActions
// } from 'vuex'

export default {
  name: 'Icon',
  props: {
    file: {
      type: String,
      required: false,
      default: () => ''
    },
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    texts: {
      type: Array,
      required: false,
      validator: (value) => value.length
    },
    icon: {
      type: Array,
      required: false,
      default: () => ['far', 'id-badge']
    },
    border: {
      type: Boolean,
      required: false,
      default: true
    },
    borderColor: {
      type: String,
      required: false
    },
    changeImage: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'base64'
    }
  },
  data: () => ({
    dataFile: ''
  }),
  created () {
    this.dataFile = this.file
  },
  computed: {
    // ...mapGetters([])
    takeModifier () {
      const block = 'icon'
      const classList = []
      if (this.border) {
        classList.push(`${block}--border`)
      }
      if (this.borderColor === 'blue') {
        classList.push(`${block}--blue`)
      }
      if (this.borderColor === 'red') {
        classList.push(`${block}--red`)
      }
      return classList
    },
    takeLabelModifier () {
      const block = 'icon__label'
      const classList = []
      if (this.changeImage) {
        classList.push(`${block}--button`)
      }
      return classList
    }
  },
  methods: {
    // ...mapActions([])
    doCreateImage (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      try {
        reader.onload = () => {
          this.doEmitFile(reader.result)
        }
      } catch {
        console.log('error')
      }
    },
    doSelectFile (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.doCreateImage(files[0])
    },
    doEmitFile (e) {
      this.dataFile = e
      this.$emit('emit', this.dataFile)
    }
  },
  filters: {},
  watch: {}
}
</script>

<style lang="sass" scoped>
.icon
  display: flex
  flex-direction: column
  align-items: center
  width: 112px
  font-size: 14px
  &__image
    height: 104px
    width: 88px
    margin: 16px 8px
    border-radius: 0px

  &__label
    border-top: 1px solid black
    width: 100%
    display: flex
    text-align: center
    align-items: center
    justify-content: center
    flex-direction: row
    padding: 8px 0px
    &--icon
      padding: 0
      border: none
      width: 32px
      margin: 0
    &--button
      font-weight: 600
      color: #287AED
      cursor: pointer

  &--border
    border: 2px solid black
    border-radius: 16px
  &--blue
    border-color: #283CED
  &--red
    border-color: #ED2828

</style>
